// Generated by Framer (6b748fa)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./deNrkl8sw-0.js";

const cycleOrder = ["qEigJpil_", "FKkOmu8g9"];

const serializationHash = "framer-nHae3"

const variantClassNames = {FKkOmu8g9: "framer-v-13hagsv", qEigJpil_: "framer-v-wsdl2l"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {GZmX1Oigf: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "qEigJpil_", Phone: "FKkOmu8g9"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "qEigJpil_"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qEigJpil_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.footer {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wsdl2l", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"qEigJpil_"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-874b1b5d-94a7-4bd8-9a23-54b9dc1195b6, rgb(68, 82, 24))", ...style}} {...addPropertyOverrides({FKkOmu8g9: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nHae3.framer-j0dhh7, .framer-nHae3 .framer-j0dhh7 { display: block; }", ".framer-nHae3.framer-wsdl2l { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; min-height: 160px; overflow: hidden; padding: 80px 24px 80px 24px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nHae3.framer-wsdl2l { gap: 0px; } .framer-nHae3.framer-wsdl2l > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-nHae3.framer-wsdl2l > :first-child { margin-left: 0px; } .framer-nHae3.framer-wsdl2l > :last-child { margin-right: 0px; } }", ".framer-nHae3.framer-v-13hagsv.framer-wsdl2l { width: 380px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 160
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"FKkOmu8g9":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerdeNrkl8sw: React.ComponentType<Props> = withCSS(Component, css, "framer-nHae3") as typeof Component;
export default FramerdeNrkl8sw;

FramerdeNrkl8sw.displayName = "Footer Copy";

FramerdeNrkl8sw.defaultProps = {height: 160, width: 1200};

addPropertyControls(FramerdeNrkl8sw, {variant: {options: ["qEigJpil_", "FKkOmu8g9"], optionTitles: ["Desktop", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerdeNrkl8sw, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})